import React from "react";
import ServiceGallery from "../components/ServiceGallery";

const AllServiceScreen = () => {
  return (
    <>
      <ServiceGallery />
    </>
  );
};

export default AllServiceScreen;
